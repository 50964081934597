<template>
  <div>
    <div class="flix-bg-info flix-text-center" style="padding: 10px;">
      <div class="flix-overflow">
        <div class="flex flex-center">
          <nextAppointmentsCalendar :filter="filter"></nextAppointmentsCalendar>
        </div>
        <holidayCalendar />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    filter: [String, Number]
  },
  components: {
    holidayCalendar () { return import('@/components/holidays/calendar.vue') },
    nextAppointmentsCalendar () { return import('@/components/overview/next.vue') }
  }
}
</script>

<style lang="sass" scoped>
  .flix-overflow
    width: 100%
    box-sizing: border-box
    display: inline-block
    display: grid
    grid-template-columns: 2fr 1fr
    grid-template-rows: 1fr
    align-items: space-around
    justify-content: space-around
    margin: 20px auto
    @media (max-width: 970px)
      grid-template-columns: 1fr
      gap: 20px
      > div
        margin: 0 auto
</style>
